import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { userCardListService, changeOrderAccountService } from "../../../axios/service";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion, closeSyn } from "../../../common/js/navigateTools";
import "./index.less";
import cardList_title from "../../../common/images/bindCard/cardList_title.png";
import cardList_top from "../../../common/images/bindCard/cardList_top.png";
import cardList_bottom from "../../../common/images/bindCard/cardList_bottom.png";
import card_checked from "../../../common/images/repay/card_checked.png";

const BindCardList = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `ninjaIri=${params.ninjaIri}&ravine=${params.ravine}&mangoKiw=${params.mangoKiw}&whaleSea=${params.whaleSea}&blueberr=${params.blueberr}&kiwiDino=${params.kiwiDino}&scorpion=${params.scorpion}&firePapa=${params.firePapa}`;

    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [choosedBank, setChoosedBank] = useState({});
    const [bankList, setBankList] = useState([]); // 银行卡列表
    const [ewalletList, setEwalletList] = useState([]); // 电子钱包列表
    const [choosedBanner, setChoosedBanner] = useState(1); // 选择card类型 bank e-wallet 点进来默认E-wallet: 1
    const [choosedCard, setChoosedCard] = useState([]); // 选中card类型的列表

    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏
        getBaseInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const getBaseInfo = async () => {
        let data = await userCardListService();
        setList(data.palace);
        /*let bankList = []; let ewalletList = [];
        this.state.list.map(item => {
            if(item.deferring == "Bank") {
                bankList = item.impressing;
            } else if(item.deferring == "E-wallet") {
                ewalletList = item.impressing;
            }
            item.impressing && item.impressing.map(item1 => {
                if(item1.hapless == 1) {
                    this.setState({
                        choosedBank: item1, // 当前选中的卡
                    })
                }
            })
        });
        this.setState({
            bankList: bankList,
            ewalletList: ewalletList
        });*/
    }
    // 跳转到bindCard添加绑卡页面
    const jumpBindCard = (type) => {
        let choosed_banner = type; // 选中的卡类型 1E-wallet 2Bank
        setChoosedBanner(choosed_banner);
        // navigate(`/continuedCasual?service=${params.service}&smell=${params.smell}&choosedCardType=${choosed_banner}&${paramsDetail}`);
        window.location.href = `/continuedCasual?service=${params.service}&smell=${params.smell}&choosedCardType=${choosed_banner}&${paramsDetail}`;
    }
    // 确认更换银行卡/e-wallet
    const confirmBtn = async (choosedItem) => {
        let data = await changeOrderAccountService({
            bindId: choosedItem.awful || "",
        });
        getBaseInfo();
        if (params.espionage == '1') {
            closeSyn(); // 关闭当前H5
        } else {
            // window.history.back();
            // window.history.go(-1);
            // window.location.reload();//强行刷新(返回上一页刷新页面)
            navigate(-1);
        }
    }
    // 选择银行卡/ewallet
    const chooseMainBank = (item) => {
        // console.log(item);
        setChoosedBank(item);
        confirmBtn(item);
    }
    // 换bank和E-wallet banner以及其列表 (不需要切换的就不使用)
    const changeCardType = (item) => {
        // console.log(item);
        let choosed_banner = item.guessed;
        setChoosedBanner(choosed_banner);
        let choosed_card = item.guessed == 2 ? bankList : ewalletList;
        setChoosedCard(choosed_card);
    }

    return (
        <div className="P-bindCardList">
            {/* 头部标题 */}
            <div className="P-bindCardList_title">
                <img src={cardList_title} className="cardList_title" />
            </div>
            {/* 银行卡列表 */}
            <div className="P-bindCardList_list">
                <div className="list_top">
                    <img src={cardList_top} />
                </div>
                <div className="list_info">
                    <div className="area">
                    {list.length > 0 && list.map((item, index) => {
                        return (
                            <div className="list" key={"cardLi" + index}>
                                <div className="list_type">
                                    <p>{item.began || ""}</p>
                                </div>
                                <ul className="list_s">
                                    {item.reddening && item.reddening.length > 0 && item.reddening.map((child, i) => {
                                        return (
                                            <li className="s_info" key={"cardLii" + i} onClick={() => chooseMainBank(child)}>
                                                {child.happenedDetectives != 0 ? (
                                                    <div className={child.happenedDetectives == 1 || child.happenedDetectives == 7 ? "limit_card" : child.happenedDetectives == 2 || child.happenedDetectives == 99 ? "error_card" : ""}>
                                                        <p>{child.sadlyMorley || ""}</p>
                                                    </div>
                                                ) : null}
                                                <div className="s_info_txt">
                                                    {child.soothing ? <img src={child.soothing} className="card_logo" /> : null}
                                                    <div className={child.cannot ? "txt" : "txt left"}>
                                                        <p className="name">{child.troubled || ""}</p>
                                                        <p className="num">{child.thumb || ""}</p>
                                                    </div>
                                                    {child.cannot ? 
                                                    <div className="choosed">
                                                        <img src={card_checked} />
                                                    </div> : null}
                                                </div>
                                            </li>
                                        )
                                    })}
                                    <li className="s_add" onClick={() => jumpBindCard(item.guessed)}>
                                        <p>Add a new {item.guessed == 1 ? "E-wallet" : "Bank Card"}</p>
                                    </li>
                                </ul>
                            </div>
                        )
                    })}
                    </div>
                </div>
                <div className="list_bottom">
                    <img src={cardList_bottom} />
                </div>
            </div>
        </div>
    )
}

export default BindCardList;