import React, { useState, useEffect } from 'react';
import './customerService.less';
import { setTitle } from "../../common/js/tools";
import { setNavExpansion, jumpToEmail, callPhoneMethod, jumpToWhatsapp, jumpToEmailNative } from '../../common/js/navigateTools';
import { contactKfInfoService } from "../../axios/service";

const CustomerService = (props) => {
    const [contactList, setContactList] = useState([]); // 展示list数据
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱标题
    useEffect(() => {
        setTitle('');
        setNavExpansion("0"); // 不隐藏头部导航栏

        // 获取客服信息
        const getContractList = async () => {
            let data = await contactKfInfoService();
            setContactList(data || []);
        }
        getContractList();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const jumpDetailContact = (item) => {
        switch (item.excuse) {
            case "email":
                // jumpToEmail(item.sapped);
                jumpToEmailNative(item.sapped, "Contact Us", "");
                break;
            case "phone":
                callPhoneMethod(item.sapped);
                break;
            case "whatsapp":
                jumpToWhatsapp(item.sapped);
                break;
            case "feedback":
                jumpToEmailNative(item.sapped, emailTitle, "");
                break;
        }

    }
    return (
        <div className="P-customerService">
            <div className='P-customerService_tips'>
                <p>If you experience any problems, feel free to reach out to us. We're committed to addressing your concerns.</p>
            </div>
            <div className='P-customerService_content'>
                {contactList && contactList.length > 0 && (
                <ul className='P-customerService_content_list'>
                    {contactList.map((item, index) => {
                        return (
                            <li className='P-customerService_content_list_li' key={"contactItem" + index} onClick={() => jumpDetailContact(item)}>
                                <div className='list_con_right'>
                                    {/* {item.femme ? (<img src={item.femme}  />) : null} */}
                                    <div className='list_con_p'>
                                        <p className='list_con_p_top'>{item.blonde || "none"}</p>
                                        <p>{item.saying || "none"}</p>
                                    </div>
                                </div>
                                {/* <img src={jumpArrow} className='list_con_left' /> */}
                            </li>
                        )
                    })}
                </ul>
                )}
            </div>
        </div>
    );
}

export default CustomerService;

