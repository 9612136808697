import React, { useState, useEffect} from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailsService, googleScoreService, userReloanService } from "../../axios/service";
import { setNavExpansion, openUrl, toGrade, jumpToHome, closeSyn, jumpToEmailNative } from '../../common/js/navigateTools';
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import "./loanDetails.less";
import scoreStarNotChoosed from "../../common/images/status/scoreStarNotChoosed.png";
import scoreStarChoosed from "../../common/images/status/scoreStarChoosed.png";
import center_tips from "../../common/images/center_tips.png";
import logoBg from "../../common/images/logoBg.png";
import sub_buttonBg from "../../common/images/confirmOfLoan/sub_buttonBg.png";
import product_logoBg from "../../common/images/product_logoBg.png";
import score_top from "../../common/images/status/score_top.png";
import closeIcon from "../../common/images/closeIcon.png";
import checkBtn from "../../common/images/status/checkBtn.png";
import checkStatusModal_bottomImg from "../../common/images/status/checkStatusModal_bottomImg.png";

const LoanDetails = (props) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `ninjaIri=${params.ninjaIri}&ravine=${params.ravine}&mangoKiw=${params.mangoKiw}&whaleSea=${params.whaleSea}&blueberr=${params.blueberr}&kiwiDino=${params.kiwiDino}&scorpion=${params.scorpion}&firePapa=${params.firePapa}`;

    const [orderStatus, setOrderStatus] = useState(null); // 订单状态  110"额度审核失败" ,151"放款中",170"放款成功(待还款),174"待还款,169"放款失败",175"还款中",179"还款失败",200"贷款结清通知",180"逾期通知",21 审核中，110审核不通过，22待绑卡，120待提现，10等待补充信息认证
    const [noticeStatusText, setNoticeStatusText] = useState(""); // status txt
    const [noticeDesText, setNoticeDesText] = useState(""); // status desc
    const [productName, setProductName] = useState(""); // 产品name
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [detail, setDetail] = useState([]); // 用户信息数据列表
    const [goLoanUrl, setGoLoanUrl] = useState(""); // 再来一单url
    const [orderNo, setOrderNo] = useState(""); // 订单order_no
    const [loanFailed, setLoanFailed] = useState({}); // 付款失败按钮显示内容 == 169
    const [scoreList, setScoreList] = useState([scoreStarNotChoosed, scoreStarNotChoosed, scoreStarNotChoosed, scoreStarNotChoosed, scoreStarNotChoosed]); // 评分列表
    const [isScore, setIsScore] = useState(0); // 是否显示评分弹框
    const [clickIndex, setClickIndex] = useState(0); // 评分分数
    const [isDelay, setIsDelay] = useState(0); // 是否展示展期按钮

    const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案

    // 放款失败 银行报错弹框
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    // 待还款，逾期状态未更新
    const [isBoxStatusNoUpdate, setIsBoxStatusNoUpdate] = useState(0); // 是否弹框 1弹 0不弹
    const [statusNoUpdateTxt, setStatusNoUpdateTxt] = useState(""); // 弹框里的文字
    const [statusNoUpdateTitle, setStatusNoUpdateTitle] = useState(""); // 弹框里的标题
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱的标题
    const [email, setEmail] = useState(""); // 邮箱地址

    const [loanStatus, setLoanStatus] = useState(""); // 查询状态标题
    const [isStatusCheck, setIsStatusCheck] = useState(true); // 展示查询状态弹框

    const [isDisplayShelf, setIsDisplayShelf] = useState(0); // 1假产品0真产品
    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏

        // 获取订单详情
        const getLoanData = async () => {
            let data = await loanDetailsService();
            // console.log(data);
            setOrderStatus(data.anemic || ""); // data.anemic || ""
            setNoticeStatusText(data.rubbed || "");
            setNoticeDesText(data.bought || "");
            setProductName(data.plume || "");
            setProductLogo(data.light || "");
            setDetail(data.earlier && data.earlier.considerably || []);
            setOrderNo(data.holborn || "");
            setLoanFailed(data.surgeon || {});
            setGoLoanUrl(data.individual || "");
            setIsScore(data.couldn || 0);
            setIsDelay(data.competent || 0);

            setAutoAmount(data.agreementPlace || 0);
            setAutoAmountTxt(data.specificDeadline || "");
            setBankCard(data.staying || "");
            setBankName(data.troubled || "");
            setBankText(data.detialTill || "");

            // 放款失败 银行报错弹框
            setIsBoxBank(data.edgestoCould || 0); // 是否弹框 1弹 0不弹 
            setLoanFailedTxt(data.sadlyMorley || ""); // 弹框里的文字
            setLoanFailedTitle(data.thereCarter || ""); // 弹框里的标题
            setLoanFailedType(data.happenedDetectives || 0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡 

            // 待还款，逾期状态未更新
            setIsBoxStatusNoUpdate(data.waitingRepaid || 0); // 是否弹框 1弹 0不弹 
            setStatusNoUpdateTxt(data.usuallyShould || "") // 弹框里的文字
            setStatusNoUpdateTitle(data.twelveMorley || "") // 弹框里的标题
            setEmailTitle(data.somehowHimself || ""); // 邮箱的标题
            setEmail(data.answered || ""); // 邮箱地址

            setLoanStatus(data.agrees || ""); // 查询状态标题

            setIsDisplayShelf(data.stranger); // 1假产品0真产品
        }
        getLoanData();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 去还款页 - 单期
    const goPayPeriod = () => {
        // navigate(`/firstLittle?service=${params.service}&smell=${params.smell}&${paramsDetail}`);
        window.location.href = `/firstLittle?service=${params.service}&smell=${params.smell}&${paramsDetail}`;
    }
    // 去还款页 - 展期
    const goPayDelay = () => {
        // navigate(`/pattedAirlock?service=${params.service}&smell=${params.smell}&${paramsDetail}`);
        window.location.href = `/pattedAirlock?service=${params.service}&smell=${params.smell}&${paramsDetail}`;
    }
    // 跳转换绑卡列表（更换账户）
    const goBindCardList = () => {
        // 跳转 换绑卡（更换账户）
        if (loanFailed.worth.pounds != undefined && loanFailed.worth.pounds.length > 0) {
            // navigate(`${loanFailed.worth.pounds}&${paramsDetail}`);
            window.location.href = `${loanFailed.worth.pounds}&${paramsDetail}`;
        }else {
            // navigate(`/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`);
            window.location.href = `/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`;
        }
    }
    // 原卡重试
    const reTryOriginCard = async () => {
        let data = await userReloanService({
            orderNo: orderNo,
        });
        // console.log(data);
        // navigate(`${data.simply}&${paramsDetail}`); // 跳转订单详情页
        window.location.href = `${data.simply}&${paramsDetail}`;
    }
    // 再来一单
    const goToLoan = () => {
        // 跳转原生页面
        let url_type = goLoanUrl.slice(0, 4);
        if(url_type == "http") {
            window.location.href = `${goLoanUrl}&${paramsDetail}`;
        } else if(url_type == "dg:/") {
            openUrl(`${goLoanUrl}`); // ?relations=params.smell
        }
    }
    // 回到首页
    const goHome = () => {
        jumpToHome();
    }
    // 关闭评分弹框
    const closeScoreModal = () => {
        setIsScore(0);
    }
    // 点击评分
    const clickScore = (item, index) => {
        let the_scoreList = scoreList;
        the_scoreList.map((child, i) => {
            if(i <= index) {
                return the_scoreList[i] = scoreStarChoosed;
            } else {
                return the_scoreList[i] = scoreStarNotChoosed;
            }
        });
        setScoreList(the_scoreList);
        setClickIndex(index + 1);
    }
    // 提交评分
    const upScore = async () => {
        if(clickIndex == 0) {
            ToastType('Toast', 'Please select');
            return;
        }
        let data = await googleScoreService({
            clickIndex: clickIndex,
        });
        toGrade();
        setIsScore(0);
        setClickIndex(0);
    }
    // 跳转换绑卡
    const changeAccount = () => {
        // navigate(`/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`);
        window.location.href = `/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`;
    }
    // 关闭 放款失败银行错误弹框
    const closeBankModal = () => {
        closeSyn(); // 关闭当前H5
    }
    // 关闭 还款状态未更新弹框
    const closeStatusModal = () => {
        setIsBoxStatusNoUpdate(0);
    }
    // 跳转带标题和订单号的邮箱 js交互
    const jumpToEmailNativigate = () => {
        jumpToEmailNative(email, emailTitle, orderNo);
    }

    return (
        <div className="P-loanDetails">
            {/* 金额和状态描述区域 */}
            <div className="P-loanDetails_status">
                <div className={orderStatus == 110 || orderStatus == 169 || (orderStatus == 151 && loanFailed && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? "status_amount fail_amount" : "status_amount"}>
                    <p>{autoAmount || "0"}</p>
                </div>
                <div className={orderStatus == 110 || orderStatus == 169 || (orderStatus == 151 && loanFailed && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? "status_txt fail_txt" : "status_txt"}>
                    <p>{autoAmountTxt || ""}</p>
                </div>
            </div>
            {/* 信息展示区域 */}
            {orderStatus == 110 || orderStatus == 200 ? (
                <div className="P-loanDetails_tips">
                    <img src={center_tips} className="center_tips" />
                    <p className="tips">{noticeDesText || ""}</p>
                </div>
            ) : (
                <div className="P-loanDetails_info">
                    {/* 订单状态查询按钮 */}
                    {orderStatus == 169 || (orderStatus == 151 && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? null : 
                    <div className={orderStatus == 180 ? "statusCheck ellipsis dueStatus" : "statusCheck ellipsis"}>
                        <p className="statusCheck_status">{loanStatus || ""}</p>
                        <img src={checkBtn} onClick={() => setIsStatusCheck(true)} className="checkBtn" />
                    </div>}
                    <div className="infoArea">
                        {/* 产品信息 */}
                        <div className="infoArea_product">
                            <div className="product_logo">
                                {productLogo && productLogo.length > 0 ? <img src={product_logoBg} className="logoBg" /> : <img src={logoBg} className="logoBg" />}
                                {productLogo && productLogo.length > 0 ? <img src={productLogo} className="logo" /> : null}
                            </div>
                            <p className="product_name">{productName || ""}</p>
                        </div>
                        <ul className="infoArea_detail">
                            {detail && detail.length > 0 && detail.map((item, index) => {
                                return (
                                    <li key={"loanDeI" + index}>
                                        <p className="detail_left">{item.saying || ""}</p>
                                        <p className="detail_right">{item.sapped || ""}</p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {/* 银行卡/失败提示展示区域 */}
                    {orderStatus != 110 && orderStatus != 200 ? (
                        <div className="P-loanDetails_bankArea">
                            <p className="bankArea_title">{bankText || ""}</p>
                            <div className="bankArea_info">
                                <p className="bank_name">{bankName || ""}</p>
                                <p className="bank_num">{bankCard || "-"}</p>
                            </div>
                        </div>
                    ) : null}
                    {orderStatus == 169 || (orderStatus == 151 && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? (
                        <div className="P-loanDetails_errorTips">
                            <p className="errorTips_title">{noticeStatusText || ""}</p>
                            <p className="errorTips_desc">{noticeDesText || ""}</p>
                        </div>
                    ) : null}
                    {/* 按钮 */}
                    {orderStatus == 174 || orderStatus == 180 ? (
                        <div className="P-loanDetails_button">
                            {/* 延期还款 */}
                            {isDelay ? 
                                <div className="buttonArea">
                                    <Button
                                    content= "Extension"
                                    clickFunc= {() => goPayDelay()}
                                    btnStyle= {{backgroundImage: `url(${sub_buttonBg})`}} />
                                </div> 
                            : null}
                            {/* 正常还款 */}
                            <div className="buttonArea">
                                <Button
                                content= "Repay"
                                clickFunc= {() => goPayPeriod()} />
                            </div>
                        </div>
                    ) : null}
                    {orderStatus == 169 || (orderStatus == 151 && loanFailed && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? (
                        <div className="P-loanDetails_button">
                        {/* 原卡重试 */}
                        {loanFailed.divisional && loanFailed.divisional.attractive == 1 ? 
                            <div className="buttonArea">
                                <Button
                                content= {loanFailed.divisional.forehead}
                                clickFunc= {() => reTryOriginCard()}
                                btnStyle= {{backgroundImage: `url(${sub_buttonBg})`}} />
                            </div> 
                        : null}
                        {/* 更换账户 */}
                        {loanFailed.worth && loanFailed.worth.attractive == 1 ?
                            <div className="buttonArea">
                                <Button
                                content= {loanFailed.worth.forehead}
                                clickFunc= {() => goBindCardList()} />
                            </div>
                        :null}
                    </div>
                    ) : null}
                </div>
            )}
            {/* 按钮 */}
            <div className="P-loanDetails_button paperBtn">
                {/* 审核失败 - 返回首页 */}
                {orderStatus == 110 && (
                    <div className="buttonArea">
                        <Button
                        content= "Home"
                        clickFunc= {() => goHome()} />
                    </div>
                )}
                {/* 再来一单 */}
                {orderStatus == 200 && goLoanUrl.length > 0 && (
                    <div className="buttonArea">
                        <Button
                        content= "Apply Again"
                        clickFunc= {() => goToLoan()} />
                    </div>
                )}
            </div>

            {/* 评分弹框 */}
            {isScore ? (
                <div className="P-loanDetails_score">
                    <div className="score_mask"></div>
                    <div className="score_info">
                        <div className="score_top">
                            <img src={score_top} className="score_topIcon" />
                            <img src={closeIcon} className="closeIcon" onClick={() => closeScoreModal()} />
                        </div>
                        <div className="info">
                            <p className="info_desc">Hi there!</p>
                            <p className="info_desc">Don't forget to give us your thoughts! Your feedback and ideas drive our improvement!</p>
                            <div className="star">
                                {scoreList.map((item, index) => {
                                    return (
                                        <img src={item} key={"scoreStar" + index} onClick={() => clickScore(item, index)} className={index < clickIndex ? "rotate_star" : ""} />
                                    )
                                })}
                            </div>
                            <div className="buttonArea">
                                <Button
                                content= "Confirm"
                                clickFunc= {() => upScore()} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}

            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                isClose
                closeFunc={() => closeBankModal()}
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle}
                loanFailedTxt={loanFailedTxt}
                btnArray={[{
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {}
                }]} />
            ) : null}

            {/* 查询详情状态弹框 110 200 放款失败 不需要 */}
            {orderStatus == 110 || orderStatus == 200 || orderStatus == 169 || (orderStatus == 151 && Object.keys(loanFailed).length > 0 && loanFailed.agrees == 1) ? null : isStatusCheck ? (
                orderStatus == 21 || orderStatus == 151 || orderStatus == 174 || orderStatus == 180 ? (
                    <ErrorModal
                    isClose={isDisplayShelf == 1 ? false : true}
                    isCloseSync={isDisplayShelf == 1 ? true : false}
                    closeFunc={() => setIsStatusCheck(false)}
                    closeSync={() => setIsStatusCheck(false)}
                    loanFailedTitle={loanStatus}
                    loanFailedTxt={noticeDesText}
                    btnArray={[]}>
                        <img src={checkStatusModal_bottomImg} className="checkStatusModal_bottomImg" />
                    </ErrorModal>
                ) : null
            ) : null}
        </div>
    )
}

export default LoanDetails;