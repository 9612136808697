import React, { useState } from 'react';
import Button from '../button';
import './index.less';
import cardList_top from "../../common/images/bindCard/cardList_top.png";
import cardList_bottom from "../../common/images/bindCard/cardList_bottom.png";
import closeIcon from "../../common/images/closeIcon.png";

const Modal = (props) => {
    const { titleContent, close, children } = props;
    return (
        <div className='M-modal'>
            <div className='M-modal_mask'></div>
            <div className='M-modal_content'>
                <div className='top'>
                    <img src={cardList_top} />
                    <p>{titleContent || ""}</p>
                </div>
                <div className='content'>
                    <div className='scrollArea'>
                        <img src={closeIcon} className='closeIcon' onClick={() => close()} />
                        <div className='list'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;