import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion ,jumpToHome } from '../../common/js/navigateTools';
// import { prohibitedProductService } from '../../axios/service';
import './jointLogonFail.less';
import custom_goHomeBtn from '../../common/images/status/jointFail_goHomeBtn.png';
import center_tips from '../../common/images/center_tips.png';
import jointFail_top from '../../common/images/status/jointFail_top.png';
const JointLogonFail = (props) => {
    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏
        // this.getProhibitedProduct();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    // 获取小黑屋展示信息
    /*async getProhibitedProduct = () => {
        let data = await prohibitedProductService();
        console.log(data);
    }*/
    // 去首页
    const goIndex = () => {
        jumpToHome();
    }
    return (
        <div className="jointLogonFail-wrap">
            <div className='jointLogonFail-wrap_content'>
                <img src={jointFail_top} className='jointFail_top' />
                {/* tips */}
                <div className='jointLogonFail-wrap_content_tips'>
                    <img className='center_tips' src={center_tips} />
                    <div className='tips_content'>
                        <p>Sorry, your qualifications do not align with the prerequisites for applying for this product.</p>
                        <p className='tips_content_inner'>Please try applying for other products.</p>
                    </div>
                </div>
            </div>
            <div className='jointLogonFail-wrap_btnArea'>
                {/* <p className='tips_homepage' onClick={this.goIndex.bind(this)}>Homepage</p> */}
                <img src={custom_goHomeBtn} className='tips_homepage' onClick={() => goIndex()} />
            </div>
        </div>
    );
}

export default JointLogonFail;

