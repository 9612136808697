import React, { useState, useEffect, useRef } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import qs from 'qs';
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion } from "../../../common/js/navigateTools";
import { loanPlayDetailService } from "../../../axios/service";
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Button from "../../../components/button";
import "./repayment.less";
import bankError_confirm_tipsArrow from "../../../common/images/bankError/bankError_confirm_tipsArrow.png";

const RepaymentOfPeriod = (props) => {
    // 路由params
    // const location = useLocation();
    // const params = qs.parse(location.search.substring(1, location.search.length));
    // const paramsDetail = `ninjaIri=${params.ninjaIri}&ravine=${params.ravine}&mangoKiw=${params.mangoKiw}&whaleSea=${params.whaleSea}&blueberr=${params.blueberr}&kiwiDino=${params.kiwiDino}&scorpion=${params.scorpion}&firePapa=${params.firePapa}`;
    // 跳转路由 
    // const navigate = useNavigate();

    const [productName, setProductName] = useState("");
    const [repayPlanDetail, setRepayPlanDetail] = useState({}); // 内涵details
    const [totalAmount, setTotalAmount] = useState(""); // 总金额
    const [amount, setAmount] = useState(null); // 带货币符号的总金额
    const [note, setNote] = useState("");

    const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示

    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏
        
        const getPeriod = async () => {
            let data = await loanPlayDetailService();
            setProductName(data.plume || "");
            setRepayPlanDetail(data.according || {});
            setTotalAmount(data.kirby || "");
            setAmount(data.broadly || "");
            setNote(data.children || "");

            setAutoAmount(data.agreementPlace || 0);
            setAutoAmountTxt(data.specificDeadline || "");
        }
        getPeriod();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    
    const cardRef = useRef();
    let repayConfirm = () => {
        cardRef.current.repayCommit();
    }
    return (
        <div className="P-repayment">
            {/* 金额和状态描述区域 */}
            <div className="P-repayment_amount">
                <div className="amount">
                    <p>{autoAmount || "0"}</p>
                </div>
                <div className="amount_txt">
                    <p>{autoAmountTxt || "Total Repayment"}</p>
                </div>
            </div>
            {/* 还款计划信息区域 */}
            <div className="P-repayment_planInfo">
                {repayPlanDetail.grinned && repayPlanDetail.grinned.length > 0 && (
                <ul className="repay_info">
                    {repayPlanDetail.grinned.map((item, index) => {
                        return (
                            <li key={"conf_repayI" + index}>
                                <div className="repayInfo_area">
                                        <div className="index">
                                            <p className="index_num">{item.kicked.substring(0, 1) || "-" }</p>
                                            <p className="index_th">{item.kicked.substring(1, item.kicked.length) || ""}</p>
                                        </div>
                                        <div className="info ellipsis">
                                            <div className="info_amount">
                                                <p>{item.blonde || ""}</p>
                                                <p>{item.peopleGood || ""}</p>
                                            </div>
                                            <div className="info_date">
                                                <p>{item.fifteen || ""}</p>
                                                <p>{item.blame || ""}</p>
                                            </div>
                                        </div>
                                </div>
                                {index == 0 ? (
                                        <div className="repayInfo_tips">
                                            <div className="tips">
                                                <p>{note || ""}</p>
                                            </div>
                                            <img src={bankError_confirm_tipsArrow} className="tipsArrow" />
                                        </div>
                                ) : null}
                            </li>
                        )
                    })}
                </ul>
                )}
                <div className="cardArea">
                    <RepaymentOfCard 
                    ref={cardRef}
                    amount={repayPlanDetail && repayPlanDetail.grinned && repayPlanDetail.grinned[0].kirby || ""}
                    isDelay={0} />
                </div>
            </div>
            {/* 跳转按钮 */}
            <div className="P-repayment_btnArea">
                <Button
                content="Confirm"
                clickFunc={() => repayConfirm()} />
            </div>
        </div>
    )
}

export default RepaymentOfPeriod;