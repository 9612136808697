import React, { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { useLocation } from "react-router-dom";
import qs from 'qs';
import { ToastType } from "../../common/js/toastList";
import { getLoanPaymentService, generateVcodeService } from '../../axios/service';
import "./repaymentOfCard.less";
import card_checked from "../../common/images/repay/card_checked.png";
import noneImg from "../../common/images/repay/noneImg.png";

const RepaymentOfCard = (props, ref) => {
    // 路由params
    const location = useLocation();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `ninjaIri=${params.ninjaIri}&ravine=${params.ravine}&mangoKiw=${params.mangoKiw}&whaleSea=${params.whaleSea}&blueberr=${params.blueberr}&kiwiDino=${params.kiwiDino}&scorpion=${params.scorpion}&firePapa=${params.firePapa}`;

    const [ewallet, setEwallet] = useState([]);
    const [bank, setBank] = useState([]);
    const [overcounter, setOvercounter] = useState([]);
    const [choosedCardList, setChoosedCardList] = useState([]); // 选中的还款方式列表
    const [choosedCardType, setChoosedCardType] = useState(1); // 选中的还款方式类型
    const [checkedCardIndex, setCheckedCardIndex] = useState(null); // 选中的还款方式id
    const [amount, setAmount] = useState(null);

    const [showAllList, setShowAllList] = useState(false); // 是否展示全部数组
    const [show_list, setShowList] = useState([]); // 有view-all 展示的数组

    const cardTypeList = [{
        typeIndex: 1,
        typeName: "E-WALLET",
     }, {
        typeIndex: 2,
        typeName: "BANK",
    }, {
        typeIndex: 3,
        typeName: "OVER-COUNTER",
    }];

    useEffect(() => {
        const fetchLoanPaymentInfo = async () => {
            let data = await getLoanPaymentService();
            let checked_index = data.twenty && data.twenty.length > 0 ? data.twenty[0].excuse : null; // 默认ewallet的第一个
            setEwallet(data.twenty || []);
            setBank(data.precise || []);
            setOvercounter(data.nodded || []);
            setChoosedCardList(data.twenty || []);
            setCheckedCardIndex(checked_index || "");
            // setAmount();
        }
        fetchLoanPaymentInfo();
    }, [])
    // 选择卡类型
    const chooseCardType = (type) => {
        setChoosedCardType(type);
        let chosed_list = type == 1 ? ewallet : (type == 2 ? bank : overcounter);
        setChoosedCardList(chosed_list);
        let checked_index = chosed_list.length > 0 ? chosed_list[0].excuse : null;
        setCheckedCardIndex(checked_index);
    }
    // 选择卡
    const chooseCardDetailType = (item) => {
        setCheckedCardIndex(item.excuse);
    }
    // 跳转还款页/第三方 repay提交
    const repayCommit = () => {
        if(checkedCardIndex == undefined || checkedCardIndex == "" || checkedCardIndex == null) {
            ToastType('Toast', 'Please select one');
            return;
        }
        if(choosedCardList.length === 0) {
            ToastType('Toast', "You haven't added any cards yet");
            return;
        }
        jumpToCodePage(); // 跳转到详情条码页
        // console.log(this.state.choosedCardType, this.state.checkedCardIndex);
    }
    // 跳转到code页面
    const jumpToCodePage = async () => {
        let data = await generateVcodeService('card', {
            cardType: choosedCardType,
            typeCode: checkedCardIndex,
            amount: props.amount,
            isDelay: props.isDelay
        });
        // console.log(data);
        if(data.imagine != undefined && data.imagine.length > 0) {
            // window.location.replace(data.imagine); // 替换当前详情页跳转第三方
            window.location.href = data.imagine; // 跳转第三方
            return;
        } else {
            // 跳转code页面
            // navigate(`/quietHimself?cardType=${choosedCardType}&typeCode=${checkedCardIndex}&service=${params.service}&smell=${params.smell}&isDelay=${props.isDelay}&amount=${props.amount}&${paramsDetail}`);
            window.location.href = `/quietHimself?cardType=${choosedCardType}&typeCode=${checkedCardIndex}&service=${params.service}&smell=${params.smell}&isDelay=${props.isDelay}&amount=${props.amount}&${paramsDetail}`;
        }
        
    }
    useImperativeHandle(ref, () => ({
        repayCommit
    }))

    // 展示全部
    const showAll = () => {
        setShowAllList(true);
        // setShowList(choosedCardList);
    }
    return (
        <div className="M-card">
            {/* 卡类型选择 */}
            <ul className="M-card_typeList">
                {cardTypeList.map((item, index) => {
                    return (
                        <li className={choosedCardType == item.typeIndex ? "choosedCardType" : "cardType"} key={"cardTi" + index} onClick={() => chooseCardType(item.typeIndex)}>
                            <p>{item.typeName}</p>
                        </li>
                    )
                })}
            </ul>
            {/* 选择卡 */}
            {choosedCardList && choosedCardList.length > 0 ? (
                <ul className="M-card_list">
                    {choosedCardList.map((item, index) => {
                        return (
                            <li key={"cardI" + index} onClick={() => chooseCardDetailType(item)}>
                                <div className="list_info">
                                    {item.soothing ? <img src={item.soothing} /> : null}
                                    <div className={checkedCardIndex == item.excuse ? "checkedItem" : ""}>
                                        <p>{item.employment || ""}</p>
                                    </div>
                                </div>
                                {checkedCardIndex == item.excuse ? <img src={card_checked} className="checkedImg" /> : null}
                            </li>
                        )
                    })}
                </ul>
            ) : (
                <div className="M-card_none">
                    <img src={noneImg} className="noneImg" />
                </div>
            )}
        </div>
    )
}

export default forwardRef(RepaymentOfCard);