import React, { useState, useEffect, useRef } from "react";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion } from "../../../common/js/navigateTools";
import { delayDetailService } from '../../../axios/service';
import RepaymentOfCard from "../../../components/card/repaymentOfCard";
import Button from "../../../components/button";
import "./repayment.less";

const RepaymentOfDelay = (props) => {

    const [productLogo, setProductLogo] = useState("");
    const [productName, setProductName] = useState("");
    const [delayShouldPayAmount, setDelayShouldPayAmount] = useState(""); // 还款金额
    const [orderDetail, setOrderDetail] = useState([]);
    const [amount, setAmount] = useState(""); // 借款金额

    const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示

    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏

        const getPeriod = async () => {
            let data = await delayDetailService();
            setProductLogo(data.light || "");
            setProductName(data.plume || "");
            setDelayShouldPayAmount(data.perfectly || "");
            setOrderDetail(data.clear && data.clear.considerably || []);
            setAmount(data.broadly || "");

            setAutoAmount(data.agreementPlace || 0);
            setAutoAmountTxt(data.specificDeadline || "");
        }
        getPeriod();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const cardRef = useRef();
    let repayConfirm = () => {
        cardRef.current.repayCommit();
    }
    return (
        <div className="P-repayment">
            {/* 金额和状态描述区域 */}
            <div className="P-repayment_amount">
                <div className="amount">
                    <p>{autoAmount || "0"}</p>
                </div>
                <div className="amount_txt">
                    <p>{autoAmountTxt || "Total Repayment"}</p>
                </div>
            </div>
            {/* 展期信息区域 */}
            <div className="P-repayment_delayInfo">
                {orderDetail && orderDetail.length > 0 && (
                    <ul className="delayInfo">
                        {orderDetail.map((item, index) => {
                            return (
                                <li key={"delayI" + index}>
                                    <p className="info_left">{item.saying || ""}</p>
                                    <p className="info_right">{item.sapped || ""}</p>
                                </li>
                            )
                        })}
                    </ul>
                )}
                <div className="cardArea">
                    <RepaymentOfCard 
                    ref={cardRef}
                    amount={delayShouldPayAmount.split(",").join('') || ""}
                    isDelay={1} />
                </div>
            </div>
            {/* 跳转按钮 */}
            <div className="P-repayment_btnArea">
                <Button
                content="Confirm"
                clickFunc={() => repayConfirm()} />
            </div>
        </div>
    )
}

export default RepaymentOfDelay;