export default {
    loanDetailConfirm: "/theFifteen", // 确认用款页详情
    loanDetailPush: "/minutesPretty", // 确认用款
    loanDetails: "/mindiGreatest", // 订单详情页
    googleScore: "/confused", // google评分
    userReloan: "/being", // 原卡重试确认订单
    loanPlayDetail: "/cheerfulnessPlanned", // 获取还款计划信息
    getLoanPayment: "/aMorley", // 获取还款方式
    delayDetail: "/patientsQuite", // 展期详情页
    cardInit: "//pet/lemonXiao", // 获取绑卡信息（第五项）
    bindCardNew: "//pet/jellybeanGar", // 提交绑卡（第五项）
    userCardList: "/particularShook", // 用户银行卡列表
    changeOrderAccount: "/mrInspector", // 更换银行卡（会触发重新打款）
    contactKfInfo: "/himoutSecret", // 获取客服页面数据
    generateVcode: "/myResult", // 获取还款code码
    // prohibitedProduct: "/uiengllli", // 禁止产品详情 - 小黑屋
    planList: "/treeLogWork", // 确认用款页假产品数据变更接口
}