import React, { useEffect, Fragment } from "react";
import Button from "../button";
import "./index.less";
import closeIcon from "../../common/images/closeIcon.png";

const ErrorModal = (props) => {
    const { loanFailedType, loanFailedTitle, loanFailedTxt, btnArray, isClose, closeFunc, children, isCloseSync, closeSync } = props;
    useEffect(() => {
    })
    return (
        <Fragment>
        {/*<div className="M-errorModal">*/}
            <div className="M-errorModal_mask"></div>
            <div className="M-errorModal_info">
                {isClose ? <img src={closeIcon} className="closeIcon" onClick={() => closeFunc()} /> : null}
                <div className="infoArea">
                    <p className="infoArea_loanFailedTitle">{loanFailedTitle || "-"}</p>
                    <p className="infoArea_loanFailedTxt">{loanFailedTxt || "-"}</p>
                    {children}
                    {btnArray && btnArray.length == 1 ? (
                        <div className="infoArea_btn">
                            <Button
                            content={btnArray[0].content}
                            clickFunc={btnArray[0].clickFunc} />
                        </div>
                    ) : null}
                </div>
                {btnArray && btnArray.length > 1 ? (
                    <div className="btnArea">
                        {btnArray.map((item, index) => {
                            return (
                                <div className="btnArea_btn" key={"errModalI" + index}>
                                    <Button
                                    content={item.content}
                                    clickFunc={item.clickFunc}
                                    btnStyle={item.btnStyle} />
                                </div>
                            )
                        })}
                    </div>
                ) : null}
                {isCloseSync ? 
                <div className='closeSyncBtn'>
                    <Button
                    content="Confirm"
                    clickFunc={() => closeSync()} />
                </div> : null}
            </div>    
        {/*</div>*/}
        </Fragment>
    )
}

export default ErrorModal;