import React, { useState, useEffect, useRef } from "react";
import JsBarcode from "jsbarcode";
import { setTitle } from "../../../common/js/tools";
import { setNavExpansion } from "../../../common/js/navigateTools";
import { generateVcodeService } from '../../../axios/service';
import { ToastType } from "../../../common/js/toastList";
import "./repaymentOfCode.less";
import copyIcon from "../../../common/images/repay/copy.png";

const RepaymentOfCode = (props) => {
    var timer = null; // 倒计时

    const [details, setDetails] = useState([]); // 还款信息
    const [amount, setAmount] = useState(""); // 金额
    const [paymetMethod, setPaymetMethod] = useState(""); // 付款方式
    const [paymetMethodImg, setPaymetMethodImg] = useState(""); // 付款方式图片
    const [paymentCode, setPaymentCode] = useState(""); // 还款码

    const [barcode, setBarcode] = useState(""); // 条形码code（条形码需要h5调用三方生成）
    const barcodeRef = useRef("");
    barcodeRef.current = barcode;

    const [payStep, setPayStep] = useState([]); // 还款步骤指引
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [productName, setProductName] = useState(""); // 产品名称

    const [countdownNum, setCountdownNum] = useState(0); // 倒计时时间
    const countdownRef = useRef(0);
    countdownRef.current = countdownNum;
    const [countNum, setCountNum] = useState("00 : 00 : 00"); // 转换后的倒计时时间
    const [hoursNum, setHoursNum] = useState(0); // 倒计时小时

    const [fraud, setFraud] = useState(0); // 放诈

    const [autoAmount, setAutoAmount] = useState(null); // 上方显示的金额
    const [autoAmountTxt, setAutoAmountTxt] = useState(""); // 上方显示的金额提示

    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏导航栏

        const getCodeInfo = async () => {
            let data = await generateVcodeService('code', {});
            setDetails(data.detective || []);
            setAmount(data.broadly || "");
            setPaymetMethod(data.plenty || "");
            setPaymetMethodImg(data.outside || "");
            setPaymentCode(data.dental || "");

            const now_barcode = data.edges || ""; // data.edges ||
            barcodeRef.current = now_barcode; // 获取最新的还款条形码
            setBarcode(now_barcode);
            generateBarcode(now_barcode); // 生成条形码

            setPayStep(data.doors || []);
            
            const now_countdownNum = data.squeaky || 0;
            countdownRef.current = now_countdownNum; // 获取最新的倒计时
            setCountdownNum(now_countdownNum);
            timerInterval(now_countdownNum); // 倒计时开始倒计时

            setFraud(data.baize || 0);

            setAutoAmount(data.agreementPlace || 0);
            setAutoAmountTxt(data.specificDeadline || "");            
        }
        getCodeInfo();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
        return () => {
            timer && clearInterval(timer);
        }
    }, [])
    // 倒计时转换
    const setCountDown = (time) => {
        let count = time;
        if(count > 0) {
            let hour = Math.floor(count / 3600);
            let minute = Math.floor((count / 60) % 60);
            let second = Math.floor(count % 60);
            let h = hour >= 10 ? hour : `0${hour}`;
            let m = minute >= 10 ? minute : `0${minute}`;
            let s = second >= 10 ? second : `0${second}`;

            setCountNum(h + " : " + m + " : " + s);
            setHoursNum(hour);
        }
    }
    const timerInterval = (num) => {
        let time = num;
        timer = setInterval(() => {
            if(num > 0) {
                time -= 1;
                setCountDown(time);
            } else {
                clearInterval(timer);
            }
        }, 1000);
    }
    // 复制Copy
    const copy = (content) => {
        try {
            var input = document.createElement("input");
            input.value = content;
            document.body.appendChild(input);
            input.select();
            input.setSelectionRange(0, input.value.length), document.execCommand('copy');
            document.body.removeChild(input);
            // console.log(content);
        } catch (error) {
            console.log(error);
        }
    }
    const copyVcode = () => {
        if(paymentCode) {
            copy(paymentCode);
            ToastType('Toast', "The copy was successful");
        } else {
            ToastType('Toast', "The copy was failed");
        }
    }
    // 生成条形码
    const generateBarcode = (code) => {
        if(code.length > 0) {
            JsBarcode("#barcode", code, {
                displayValue: false, // 不显示文字
                background: "transparent", // 隐形背景
                margin: 0, // 整个条形码的外面距 -> 0 默认10
            });
        }
    }

    return (
        <div className="P-code">
            {/* 金额和状态描述区域 */}
            <div className="P-code_amount">
                <div className="amount">
                    <p>{autoAmount || "0"}</p>
                </div>
                <div className="amount_txt">
                    <p>{autoAmountTxt || "Total Repayment"}</p>
                </div>
            </div>
            <div className="P-code_info">
                {/* 还款信息 */}
                {details && details.length > 0 && (
                    <ul className="info_repay">
                        {details.map((item, index) => {
                            return (
                                <li key={"codeRi" + index}>
                                    <p>{item.saying || ""}</p>
                                    <p>{item.sapped || ""}</p>
                                </li>
                            )
                        })}
                    </ul>
                )}
                {/* 倒计时 & 还款码 */}
                <div className="info_countAndCode">
                    <div className="countdownAndCode">
                        <div className="countdown">
                            <p>{countNum || ""}</p>
                        </div>
                        <div className="code">
                            <p>{paymentCode || ""}</p>
                        </div>
                    </div>
                    <img src={copyIcon} onClick={() => copyVcode()} className="copy" />
                </div>
                {/* 倒计时提示 */}
                <div className="countdown_tips">
                    <p>Valid for {countNum} s. If it expires, you need to use a new repayment code to repay!</p>
                </div>
                {/* 条形码code（条形码需要h5调用三方生成） */}
                <div className='barcodeArea' style={{height: barcode.length <= 0 ? "0" : ""}}>
                    <svg className="barCode" id="barcode"></svg>
                </div>
                {/* 还款步骤指引 */}
                <div className="step">
                    <div className='step_guidelines'>
                        <p>Payment Assistance</p>
                    </div>
                    {payStep.map((item, index) => {
                        return (
                            <div className="step_info" key={"repayStepIndex" + index}>
                                <div className="step_title">
                                    <p className='step_title_index'>{index + 1}.</p>
                                    <p>{item.employment}</p>
                                </div>
                                <div className="step_info">
                                    {item.pauvre && item.pauvre.map((subitem, i) => {
                                        return <p key={"repayStepDI" + i}>{i + 1}. {subitem}</p>
                                    })
                                }
                            </div>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default RepaymentOfCode;