import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const LoanAgreement = (props) => {
    useEffect(() => {
        setTitle('Loan Agreement');
        setNavExpansion("0");
    }, [])
    return (
        <div className="P-agreement">
            <p className="title">Loan Agreement</p>
            <div>
                <p className='subtitle'>Company Information</p>
                <p>- Company Name: PASIG-BORONGAN LENDING CORP.</p>
                <p>- SEC Registration Number: CS201709377</p>
                <p>- Authorization Certificate Number: NO.2154</p>
            </div>
            <div>
                <p className='subtitle'>Loan Provider Information</p>
                <p>- Company Name: LINK CREDIT LENDING INVESTORS INC.</p>
                <p>- SEC Registration Number: CS201914644</p>
                <p>- Authorization Certificate Number: NO.3072</p>
            </div>
            <div>
                <p className='subtitle'>1. Introduction</p>
                <p>Thank you for choosing PASIG-BORONGAN LENDING CORP. for your loan services. We are committed to providing transparent, secure, and flexible loan solutions. This policy aims to clearly outline the loan terms, conditions, and privacy protection measures to ensure you fully understand our services. Please read the following content carefully before using our services.</p>
            </div>
            <div>
                <p className='subtitle'>2. Loan Product Description</p>
                <p>2.1 Loan Amount Range  </p>
                <p>We offer loan amounts ranging from ₱2,000 to ₱50,000 to meet different financial needs.</p>
                <p>2.2 Loan Term</p>
                <p>The loan term ranges from 91 days to 180 days, tailored to suit different repayment capacities and needs.</p>
                <p>2.3 Annual Interest Rate and Fees  </p>
                <p>The annual interest rate (APR) ranges between 20% and 30%. In addition to the annual interest rate, there may be a service fee of 1% to 2% and other related charges.</p>
                <p>2.4 Loan Purpose</p>
                <p>Loans are only to be used for legal purposes, and users must comply with relevant laws and regulations.</p>
                <p>2.5 Maximum Loan Amount</p>
                <p>The loan amount will be determined based on the user's credit rating, repayment history, income level, and other comprehensive factors to ensure a reasonable and affordable loan amount.</p>
            </div>
            <div>
                <p className='subtitle'>3. Application Process</p>
                <p>3.1 Application Requirements</p>
                <p>To ensure a smooth loan application process, please provide the following information:</p>
                <p>- Personal Information: Name, ID number, contact details, etc.</p>
                <p>- Proof of Income: Pay slips, bank statements, etc.</p>
                <p>- Residence Information: Current residence address and related proof.</p>
                <p>- Credit History: Including previous loan records and credit rating.</p>
            </div>
            <div>
                <p className='subtitle'>4. Repayment Arrangements</p>
                <p>4.1 Repayment Methods  </p>
                <p>Loan repayments can be made through various methods, including automatic deductions, bank transfers, or other specified methods, ensuring a convenient and flexible repayment process.</p>
                <p>4.2 Early Repayment</p>
                <p>Users can repay early to reduce the total interest expense. Early repayment fees will be detailed in the contract.</p>
            </div>
            <div>
                <p className='subtitle'>5. Loan Usage Suggestions</p>
                <p>- Loan Planning: Before applying for a loan, please make a reasonable repayment plan based on your financial situation to ensure timely repayment.</p>
                <p>- Repayment Reminders: We will provide repayment reminder services. Users can also set reminders to avoid missing repayment dates.</p>
                <p>- Avoiding Overdue Payments: Please ensure timely repayments. Overdue payments may result in additional charges and damage to your credit record. If you encounter repayment difficulties, please contact us promptly to seek a solution.</p>
            </div>
            <div>
                <p className='subtitle'>6. Contact Us</p>
                <p>If you have any questions about the loan policy or privacy policy, or need to exercise your rights, please contact us through the following ways:</p>
                <p>- Email: <span className='email'>support@pasigborongan.com</span></p>
                <p>- Website: <span className='email'>https://pasigborongan.com/</span></p>
            </div>
            <p>We look forward to providing you with quality service. If you have any questions or need assistance, please feel free to contact us.</p>
        </div>
    );
}

export default LoanAgreement;

