import axios from 'axios';
import qs from 'qs';
import { ToastType } from '../common/js/toastList';
import LoadingAxios from '../components/loading/loading';
// 获取params
import { Qs } from "../common/js/tools";
const params = Qs(); // 获取params
// 基础配置
const baseURL = "/klek";
const timeout = 60000;
const basicParams = {
    ravine: params.ravine || "", // 给idfv
    mangoKiw: params.mangoKiw || "", // 登录后获取的用户的sessionId
    notch: params.notch || "", // 设备是否是刘海屏 0非刘海屏 1刘海屏（用于H5全屏的情况）
};

axios.interceptors.request.use(
    config => {
        // 拦截成功
        // 加loading
        // ToastType('loading', 'loading...');

        return config;
    }, 
    error => {
        // 拦截失败
        console.error(error); // debug
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        // 拦截成功
        // 取消loading
        // ToastType('hide');

        let { conceive, wanting, easily } = response.data;
        if(conceive == "0" || conceive == "00") {
            return easily; // 返回数据
        } else {
            ToastType('Toast', wanting);
            return Promise.reject(new Error(wanting || "Http Response Error"));
        }
    },
    error => {
        ToastType('Toast', error);
        return Promise.reject(error);
    }
);

export default {
    get(url, params, data) {
        return axios({
            method: 'get',
            baseURL: baseURL,
            timeout: timeout,
            url,
            params: {...basicParams, ...params},
            data: qs.stringify(data),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Requested-With': 'XMLHttpRequest',
                'mangoKiw': params.mangoKiw || "", // 登陆后获取的用户的sessionId
            }
        })
    },
    post(url, params, data) {
        return axios({
            method: 'post',
            baseURL: baseURL,
            timeout: timeout,
            url,
            params: {...basicParams, ...params},
            data: qs.stringify(data),
            headers: {
                'Access-Control-Allow-Origin': '*',
                'X-Requested-With': 'XMLHttpRequest',
                'mangoKiw': params.mangoKiw || "", // 登陆后获取的用户的sessionId
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
        })
    }
}
