import React, { useState, useEffect} from "react";
// import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailConfirmService, loanDetailPushService, planListService } from "../../axios/service";
import { uploadRiskLoan, setNavExpansion } from "../../common/js/navigateTools";
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import './confirmOfLoan.less';

import bankError_confirm_tipsArrow from "../../common/images/bankError/bankError_confirm_tipsArrow.png";
import bankError_confirm_errorIcon from "../../common/images/bankError/bankError_confirm_errorIcon.png";
import confirm_goBank from "../../common/images/confirmOfLoan/confirm_goBank.png";
import confirm_agree from "../../common/images/confirmOfLoan/confirm_agree.png";
import confirm_notAgree from "../../common/images/confirmOfLoan/confirm_notAgree.png";

import sub_buttonBg from "../../common/images/confirmOfLoan/sub_buttonBg.png";

import shelf_amountBg from "../../common/images/confirmOfLoan/shelf_amountBg.png";
import shelf_next from "../../common/images/confirmOfLoan/shelf_next.png";
import shelf_chosedIcon from "../../common/images/confirmOfLoan/shelf_chosedIcon.png";

const ConfirmOfLoan = (props) => {
    // 路由params
    const location = useLocation();
    // console.log(qs.parse(location.search.substring(1, location.search.length)));
    // const params = Qs();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `ninjaIri=${params.ninjaIri}&ravine=${params.ravine}&mangoKiw=${params.mangoKiw}&whaleSea=${params.whaleSea}&blueberr=${params.blueberr}&kiwiDino=${params.kiwiDino}&scorpion=${params.scorpion}&firePapa=${params.firePapa}`;

    // 跳转路由
    const navigate = useNavigate();

    // state数据
    const [amount, setAmount] = useState(""); // 传值时传的字段 不带金额符号的借款金额
    const [periods, setPeriods] = useState(""); // 期限 - 传值
    const [termUnit, setTermUnit] = useState(""); // // 期限类型 - 传值
    const [amountTxt, setAmountTxt] = useState(""); // 带金额符号的借款金额
    const [amount_show, setAmountShow] = useState(""); // 展示用金额 （有逗号没有金额符号）
    const [details, setDetails] = useState([]); // 借款数据
    const [repay_plans, setRepayPlans] = useState([]); // 还款计划
    const [note, setNote] = useState(""); // 首期还款即结清提示
    const [firstPayTime, setFirstPayTime] = useState(""); // 第一期还款时间
    const [firstPeriodDay, setFirstPeriodDay] = useState(""); // 第一期借款期限
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [productName, setProductName] = useState(""); // 产品名字
    const [isChekedAgreement, setIsChekedAgreement] = useState(true); // 借款协议的选择状态
    const [cardType, setCardType] = useState(1); // 卡的类型 1.ewallet 2 bank 3 线下便利店
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案
    const [actualAmount, setActualAmount] = useState(0); //到账金额
    const [orderNo, setOrderNo] = useState(""); // 订单号
    const [isAgreeShow, setIsAgreeShow] = useState(false); // 是否显示同意借款协议
    const [isArrowShow, setIsArrowShow] = useState(false); // 是否显示跳转绑卡列表页
    
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    // 假产品
    const [isDisplayShelf, setIsDisplayShelf] = useState(0); // 1假产品 0真产品
    const [shelf_amountText, setShelfAmountText] = useState("0"); // 假产品的金额
    const [shelf_amount, setShelfAmount] = useState(0); // 假产品确认用款用的金额 默认amount
    const [shelf_repay_plans, setShelfRepayPlans] = useState([]); // 假产品的分期信息
    const shelf_amountArr = [{
        value: "5000",
        label: "5,000"
    }, {
        value: "10000",
        label: "10,000"
    }, {
        value: "15000",
        label: "15,000"
    }, {
        value: "20000",
        label: "20,000"
    }, {
        value: "25000",
        label: "25,000"
    }, {
        value: "30000",
        label: "30,000"
    }]; // 选择金额数组
    const shelf_periods = [91, 120, 180]; // 天数（期限）数组
    const [shelf_chosedPeriods, setShelfChosedPeriods] = useState(91); // 选择的天数（默认91）
    const shelf_term = [3, 6, 9]; // 期数（次数）数组
    const [shelf_chosedTerm, setShelfChosedTerm] = useState(3); // 选择的期数（默认3）
    const [shelf_width, setShelfWidth] = useState(0); // 滑动图标滑动的位置
    const [shelf_minusRange, setShelfMinusRange] = useState(0); // 滑动时，顶部与X坐标之间的差距
    const [shelf_allWidth, setShelfAllWidth] = useState(0); // 滑动时总长度
    
    useEffect(() => {
        setTitle("");
        setNavExpansion("1"); // 隐藏头部

        // 获取确认用款详情
        const getLoanData = async () => {
            let data = await loanDetailConfirmService();
            setAmount(data.broadly || 0);
            setPeriods(data.stumbling);
            setTermUnit(data.greek);
            setAmountTxt(data.shrugged || "");
            setAmountShow(data.bookArrow || "");
            setDetails(data.detective || []);
            setRepayPlans(data.famous || []);
            setNote(data.children || "");
            setFirstPayTime(data.inartistically);
            setFirstPeriodDay(data.shoulders);
            setProductLogo(data.light || "");
            setProductName(data.plume || "");
            setCardType(data.guessed);
            setBankCard(data.staying || "");
            setBankName(data.troubled || "");
            setBankText(data.detialTill || "");
            setActualAmount(data.problem || "");
            setOrderNo(data.holborn || "");
            setIsAgreeShow(data.motive || 0);
            setIsArrowShow(data.block || 0);

            setIsBoxBank(data.edgestoCould || 0);
            setLoanFailedTxt(data.sadlyMorley || "");
            setLoanFailedTitle(data.thereCarter || "");
            setLoanFailedType(data.happenedDetectives || "");

            let curr_isDisplayShelf = data.stranger;
            setIsDisplayShelf(data.stranger); // 1假产品0真产品
            if(curr_isDisplayShelf == 1) {
                // 假产品
                getPlanList({
                    amount: data.broadly || 0,
                    periods: shelf_chosedPeriods,
                    termUnit: shelf_chosedTerm,
                });
            }
        }
        getLoanData();
        
        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])

    // 改变借款协议选中状态
    const changeAgreementCheckStatus = () => {
        setIsChekedAgreement(!isChekedAgreement);
    }
    // 跳转合同详情
    const goAgreement = () => {
        // window.location.href = `/continuedDetected`;
        // props.history.push("/continuedDetected");
        navigate("/continuedDetected");
    }
    // 跳转换绑卡列表
    const changeAccount = () => {
        // props.history.push(`/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`);
        // navigate(`/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`);
        window.location.href = `/unpleasantKnowthat?service=${orderNo}&smell=${params.smell}&${paramsDetail}`;
    }
    // 关闭状态弹框
    const closeErrorModal = () => {
        setIsBoxBank(0);
    }
    // 去借款
    const goLoan = async () => {
        if (!isChekedAgreement) {
            ToastType("Toast", "Please check the note first");
            return;
        }
        let startTimeD = new Date();
        let startTime = `${parseInt(startTimeD.getTime() / 1000)}`;
        let service_data = {};
        if(isDisplayShelf) {
            // 假产品
            service_data = {
                amount: shelf_amount, // 借款金额
                periods: shelf_chosedPeriods, // 期限
                termUnit: shelf_chosedTerm, // 期限类型
            }
        } else {
            service_data = {
                amount: amount, // 借款金额
                periods: periods, // 期限
                termUnit: termUnit, // 期限类型
            };
        }
        let data = await loanDetailPushService(service_data);
        uploadRiskLoan(params.smell, startTime);
        data.partner && window.location.replace(`${data.partner}&${paramsDetail}`); // 跳转到订单详情页
        // data.partner && props.history.replace(`${data.partner}&${paramsDetail}`); // 跳转到订单详情页
        // data.partner && navigate(`${data.partner}&${paramsDetail}`, {replace: true}); // 跳转到订单详情页
    }

    // 获取假产品信息
    const getPlanList = async (shelfData) => {
        let data = await planListService(shelfData);
        // console.log(data);
        setShelfAmountText(data.amountText || "0");
        setShelfAmount(data.broadly || 0);
        setShelfRepayPlans(data.famous || []);
        let curr_shelf_width = (data.broadly - 3000) / 1000 / 62 * 100; // 金额选择滑动区域的金额占比 用于滑动条的宽度显示
        setShelfWidth(curr_shelf_width);
    }
    // 选择金额重新更新
    const choseAmount = (choseAmount) => {
        getPlanList({
            amount: choseAmount,
            periods: shelf_chosedPeriods,
            termUnit: shelf_chosedTerm,
        });
    }
    // 更换期限
    const changePeriods = (item) => {
        setShelfChosedPeriods(item);
        getPlanList({
            amount: shelf_amount,
            periods: item,
            termUnit: shelf_chosedTerm,
        });
    }
    // 更换期数
    const changeTerm = (item) => {
        setShelfChosedTerm(item);
        getPlanList({
            amount: shelf_amount,
            periods: shelf_chosedPeriods,
            termUnit: item,
        });
    }
    // 触摸开始
    const ononTouchStartFunc = (e) => {
        // console.log(e.touches[0]);
        let rangeArea = document.getElementById("rangeArea");
        // let scrollRangeArea = document.getElementById("scroll_rangeArea");
        // let curr_shelf_minusRange = e.touches[0].clientX - scrollRangeArea.offsetWidth;
        let curr_shelf_minusRange = rangeArea.offsetLeft; // e.touches[0].clientX - scrollRangeArea.offsetWidth
        // console.log(rangeArea.offsetWidth, rangeArea.offsetLeft, curr_shelf_minusRange); // , scrollRangeArea.offsetWidth
        setShelfAllWidth(rangeArea.offsetWidth);
        setShelfMinusRange(curr_shelf_minusRange);
    }
    // 触摸滑动
    const onTouchMoveFunc = (e) => {
        // console.log(e.touches[0].clientX, shelf_minusRange, shelf_allWidth);
        let width = e.touches[0].clientX - shelf_minusRange;
        moveFunc(width);
        if(shelf_amount <= 3000) {
            setShelfWidth(0);
            setShelfAmount(3000);
            if(width > 0) {
                // console.log(111);
                moveFunc(width);
            }
        }
        if(shelf_amount >= 65000) {
            setShelfWidth(100);
            setShelfAmount(65000);
            if(width < shelf_allWidth) {
                // console.log(222);
                moveFunc(width);
            }
        }
    }
    // 滑动方法
    const moveFunc = (width) => {
        let width_rate = width / shelf_allWidth;
        let one_rate = shelf_allWidth / 62; // 总长度一共被分为62份（已千为隔断, 初识为3k），one_rate一份占的比例
        let all_rate = parseInt(width / one_rate); // 滑动的长度占了多少比例，向下取整
        // console.log(width, width_rate);
        let curr_shelf_amount = all_rate * 1000 + 3000; // 滑动的得到的金额
        // console.log(all_rate);
        setShelfWidth(width_rate * 100);
        setShelfAmount(curr_shelf_amount);
    }
    // 滑动结束请求接口
    const onTouchEndFunc = () => {
        getPlanList({
            amount: shelf_amount,
            periods: shelf_chosedPeriods, // 期限
            termUnit: shelf_chosedTerm, // 期数
        });
    }

    return (
        <div className="P-confirmOfLoan">
            {!isDisplayShelf ? (
                <div className="P-confirmOfLoan_realArea">
                    {/* 顶部借款金额 */}
                    <div className="P-confirmOfLoan_amount">
                        <p className="amount">{amount_show || "0"}</p>
                    </div>
                    {/* 借款信息区域 */}
                    <div className="P-confirmOfLoan_infoArea">
                        {/* details数据 */}
                        {details && details.length > 0 && (
                            <ul className="infoArea_details ellipsis">
                                {details.map((item, index) => {
                                    return (
                                        <li key={"conf_detailsI" + index}>
                                            <p className="details_left">{item.saying || ""}</p>
                                            <p className="details_right">{item.sapped || ""}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        )}
                        {/* 银行卡信息区域 */}
                        <div className="infoArea_bank" style={{marginBottom: loanFailedType != 0 ? "100px" : "6px"}}>
                            <p className="bank_title">{bankText || "Disbursal Bank"}</p>
                            <div className="bank_info">
                                <p className="name">{bankName || "Name of Bank"}</p>
                                <div className="card">
                                    <p className="card_num">{bankCard || "-"}</p>
                                    {isArrowShow ? <img src={confirm_goBank} onClick={() => changeAccount()} className="confirm_goBank" /> : null}
                                </div>
                                {loanFailedType != 0 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                            </div>
                            {loanFailedType != 0 ? (
                                <div className="bank_error">
                                    <p className="tips">{loanFailedTxt || ""}</p>
                                    <img src={bankError_confirm_tipsArrow} className="error_tipsArrow" />
                                </div>
                            ) : null}
                        </div>
                        {/* 还款计划信息区域 */}
                        {repay_plans && repay_plans.length > 0 && (
                        <div className="infoArea_repay">
                            <p className="repay_title">Repayment Details</p>
                            <ul className="repay_info">
                                {repay_plans.map((item, index) => {
                                    return (
                                        <li key={"conf_repayI" + index}>
                                            <div className="index">
                                                <p className="index_num">{item.banker.substring(0, 1) || "-" }</p>
                                                <p className="index_th">{item.banker.substring(1, item.banker.length) || ""}</p>
                                            </div>
                                            <div className="info ellipsis">
                                                <div className="info_amount">
                                                    <p>{item.blonde || ""}</p>
                                                    <p>{item.peopleGood || ""}</p>
                                                </div>
                                                <div className="info_date">
                                                    <p>{item.broadly || ""}</p>
                                                    <p>{item.mysterious || ""}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>)}
                        {/* 还款提示 */}
                        {repay_plans && repay_plans.length > 0 ? <div className="infoArea_tips">
                            <p className="tips">{note || ""}</p>
                        </div> : null}
                    </div>
                </div>
            ): (
                <div className="P-confirmOfLoan_shelfArea">
                    {/* 顶部借款金额 */}
                    <div className="P-confirmOfLoan_amount" style={{backgroundImage: `url(${shelf_amountBg})`, paddingTop: '10px'}}>
                        <p className="amount">{shelf_amountText || "0"}</p>
                    </div>
                    {/* 假产品选择内容区域 */}
                    <div className="shelfArea_choseArea">
                        {/* 选择金额数组 */}
                        <ul className="shelfArea_choseArea_arr">
                            {shelf_amountArr.map((item, index) => {
                                return (
                                    <li className={shelf_amount == item.value ? "chosed_amount" : ""} key={"shelfAmountI" + index} onClick={() => choseAmount(item.value)}>
                                        <p>{item.label}</p>
                                    </li>
                                )
                            })}
                        </ul>
                        {/* 滑动金额区域 */}
                        <div className="shelfArea_choseArea_scrollAmount">
                            <div className="scrollAmount">
                                <p>Min</p>
                                <div className="scrollAmount_scroll" id="rangeArea">
                                    <img 
                                    src={shelf_chosedIcon} 
                                    className="shelf_chosedIcon"
                                    style={{left: `${shelf_width}%`}}
                                    onTouchStart={(e) => ononTouchStartFunc(e)}
                                    onTouchMove={(e) => onTouchMoveFunc(e)}
                                    onTouchEnd={() => onTouchEndFunc()} />
                                </div>
                                <p>Max</p>
                            </div>
                            <p className="scrollAmount_tips">You can freely choose a loan amount between 3,000 and 65,000, or select a preset amount.</p>
                        </div>
                        {/* 选择天数（期限）区域 */}
                        <div className="shelfArea_choseArea_periodsArea">
                            <p className="shelfArea_choseArea_title">Loan Term (91-180Days)</p>
                            <ul className="shelfArea_choseArea_arr">
                                {shelf_periods.map((item, index) => {
                                    return (
                                        <li className={shelf_chosedPeriods == item ? "chosed_amount" : ""} key={"shelfPeriodI" + index} onClick={() => changePeriods(item)}>
                                            <p>{item}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* 选择期数区域 */}
                        <div className="shelfArea_choseArea_termArea">
                            <p className="shelfArea_choseArea_title">Installment Term</p>
                            <ul className="shelfArea_choseArea_arr">
                                {shelf_term.map((item, index) => {
                                    return (
                                        <li className={shelf_chosedTerm == item ? "chosed_amount" : ""} key={"shelfTermI" + index} onClick={() => changeTerm(item)}>
                                            <p>{item}</p>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* 展示分期详情区域 */}
                        <ul className="shelfArea_choseArea_detailsArea">
                            <div className="detailsArea_title">
                                <p className="shelfArea_choseArea_title" style={{marginBottom: "0"}}>Repayment Details</p>
                                <img src={shelf_next} className="shelf_next" />
                            </div>
                            {shelf_repay_plans && shelf_repay_plans.length > 0 ? (
                                <ul className="detailsArea_details">
                                    {shelf_repay_plans.map((item, index) => {
                                        return (
                                            <li key={"shelfRepayI" + index}>
                                                <p>{item.banker}</p>
                                                <p>{item.broadly}</p>
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : null}
                        </ul>
                    </div>
                </div>
            )}
            {/* 固定底部区域 */}
            <div className="P-confirmOfLoan_bottom">
                {/* 借款协议 */}
                {isAgreeShow ? 
                <div className="bottom_agreement">
                    <img src={isChekedAgreement ? confirm_agree : confirm_notAgree} className="agreement_checkedStatus" onClick={() => changeAgreementCheckStatus()} />
                    <p>I have read and agreed to <span className="loanAgreement" onClick={() => goAgreement()}>Loan Agreement</span>.</p>
                </div> : null}
                {/* 按钮 */}
                <div className="bottom_btnArea">
                    <Button
                    content="Apply"
                    clickFunc={() => goLoan()} />
                </div>
            </div>
            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle}
                loanFailedTxt={loanFailedTxt}
                btnArray={[{
                    content: "Continue",
                    clickFunc: () => closeErrorModal(),
                    btnStyle: {backgroundImage: `url(${sub_buttonBg})`}
                }, {
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {}
                }]}>
                    <div className="p-confirmOfLoan_errorBank">
                        {loanFailedType != 0 ? <img src={bankError_confirm_errorIcon} className="errorBank_icon" /> : null}
                        <div className="errorBank_info">
                            <p className="bankName">{bankName || "Receiving Account"}</p>
                            <p className="bankNum">{bankCard || "-"}</p>
                        </div>
                    </div>
                </ErrorModal>
            ) : null}
        </div>
    )
}

export default ConfirmOfLoan;

