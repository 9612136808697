import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const PrivacyAgreement = (props) => {
    useEffect(() => {
        // 隐私协议
        setTitle('Privacy Agreement');
        setNavExpansion("0");
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="P-agreement">
            <p className="title">Privacy Policy</p>
            <p>This Privacy Policy outlines our policies and procedures regarding the collection, use, and disclosure of your information when you use our Service. It also informs you about your privacy rights and how the law protects you. We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information as described in this Privacy Policy. This Privacy Policy was created with the help of the Free Privacy Policy Generator.</p>
            <div>
                <p className='subtitle'>Company Information</p>
                <p>- Company Name: PASIG-BORONGAN LENDING CORP.</p>
                <p>- Company Name: PASIG-BORONGAN LENDING CORP.</p>
                <p>- Authorization Certificate Number: NO.2154</p>
            </div>
            <div>
                <p className='subtitle'>Loan Provider Information</p>
                <p>- Company Name: LINK CREDIT LENDING INVESTORS INC.</p>
                <p>- SEC Registration Number: CS201914644</p>
                <p>- Authorization Certificate Number: NO.3072</p>
            </div>
            <div>
                <p className='subtitle'>Interpretation and Definitions</p>
                <p>Interpretation</p>
                <p>Capitalized words have defined meanings under the following conditions. The definitions apply whether they appear in singular or plural form.</p>
            </div>
            <div>
                <p className='subtitle'>Definitions</p>
                <p>For the purposes of this Privacy Policy:</p>
                <p>- Account: A unique account created for you to access our Service or parts of our Service.</p>
                <p>- Affiliate: An entity that controls, is controlled by, or is under common control with a party, where "control" means owning 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority.</p>
                <p>- Application: Refers to Pesoin, the software program provided by the Company.</p>
                <p>- Company: (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to PASIG-BORONGAN LENDING CORP., 210 General Capinpin Street 4024 Binan.</p>
                <p>- Country: Refers to the Philippines.</p>
                <p>- Device: Any device that can access the Service such as a computer, cellphone, or digital tablet.</p>
                <p>- Personal Data: Any information that relates to an identified or identifiable individual.</p>
                <p>- Service: Refers to the Application.</p>
                <p>- Service Provider: Any natural or legal person who processes data on behalf of the Company. This includes third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.</p>
                <p>- Usage Data: Data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (e.g., the duration of a page visit).</p>
                <p>- You: The individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
            </div>
            <div>
                <p className='subtitle'>Collecting and Using Your Personal Data</p>
                <p>Types of Data Collected</p>
                <p>Personal Data</p>
                <p>While using our Service, we may ask you to provide certain personally identifiable information, including but not limited to:</p>
                <p>- Email address</p>
                <p>- First name and last name</p>
                <p>- Phone number</p>
                <p>- Address, State, Province, ZIP/Postal code, City</p>
                <p>- Facial information</p>
            </div>
            <div>
                <p className='subtitle'>Usage Data</p>
                <p>Usage Data is collected automatically when using the Service. This may include:</p>
                <p>- IP address</p>
                <p>- Browser type and version</p>
                <p>- Pages of our Service you visit</p>
                <p>- Time and date of your visit</p>
                <p>- Time spent on those pages</p>
                <p>- Unique device identifiers</p>
                <p>- Other diagnostic data</p>
                <p>When accessing the Service via a mobile device, we may collect additional information, including:</p>
                <p>- Type of mobile device</p>
                <p>- Mobile device unique ID</p>
                <p>- IP address of mobile device</p>
                <p>- Mobile operating system</p>
                <p>- Mobile Internet browser type</p>
                <p>- Other diagnostic data</p>
            </div>
            <div>
                <p className='subtitle'>Information Collected while Using the Application</p>
                <p>With your prior permission, we may collect:</p>
                <p>- Information regarding your location</p>
                <p>- Pictures and other information from your device's camera and photo library</p>
                <p>We use this information to provide features of our Service and to improve and customize our Service. This information may be uploaded to the Company's servers and/or a Service Provider's server or stored on your device. You can enable or disable access through your device settings.</p>
            </div>
            <div>
                <p className='subtitle'>Facial Recognition Data</p>
                <p>When using our application, we may collect facial recognition data. Please note:</p>
                <p>- Data Storage: Facial recognition data will be stored on remote servers, not locally.</p>
                <p>- Data Retention Period: Facial recognition data is retained for 14 days and then automatically deleted to ensure privacy and security.</p>
            </div>
            <div>
                <p className='subtitle'>Use of Your Personal Data</p>
                <p>The Company may use Personal Data for the following purposes:</p>
                <p>- To provide and maintain our Service, including to monitor usage.</p>
                <p>- To manage Your Account, providing access to different functionalities.</p>
                <p>- For contract performance, including the development, compliance, and undertaking of purchase contracts.</p>
                <p>- To contact You, including via email, phone, SMS, or push notifications.</p>
            </div>
            <div>
                <p className='subtitle'>Sharing Your Personal Data</p>
                <p>We may share your personal information in the following situations:</p>
                <p>- With Service Providers to monitor and analyze Service usage.</p>
                <p>- For business transfers, such as mergers or sales.</p>
                <p>- With Affiliates who will honor this Privacy Policy.</p>
                <p>- With business partners to offer products, services, or promotions.</p>
                <p>- With other users in public areas, which may be publicly distributed.</p>
                <p>- With your consent for any other purpose.</p>
            </div>
            <div>
                <p className='subtitle'>Retention of Your Personal Data</p>
                <p>We will retain your Personal Data only as long as necessary for the purposes set out in this Privacy Policy, including compliance with legal obligations, resolving disputes, and enforcing our policies. Usage Data is generally retained for a shorter period, except when used for security or functionality improvements.</p>
            </div>
            <div>
                <p className='subtitle'>Transfer of Your Personal Data</p>
                <p>Your information may be transferred to and maintained on computers located outside of your jurisdiction, where data protection laws may differ. By submitting your information, you agree to this transfer. We will take all reasonable steps to ensure your data is treated securely and in accordance with this Privacy Policy.</p>
            </div>
            <div>
                <p className='subtitle'>Delete Your Personal Data</p>
                <p>You have the right to delete your Personal Data. Our Service may allow you to delete certain information within the Service, or you can contact us to request deletion. Note that we may need to retain certain information to comply with legal obligations.</p>
            </div>
            <div>
                <p className='subtitle'>Disclosure of Your Personal Data</p>
                <p>- Business Transactions: In the event of a merger, acquisition, or asset sale, we will provide notice before transferring your Personal Data.</p>
                <p>- Law enforcement: We may disclose your Personal Data if required by law or in response to valid requests by public authorities.</p>
                <p>- Other legal requirements: We may disclose your Personal Data to comply with legal obligations, protect the Company’s rights or property, prevent wrongdoing, ensure personal safety, and protect against legal liability.</p>
            </div>
            <div>
                <p className='subtitle'>Security of Your Personal Data</p>
                <p>We strive to use commercially acceptable means to protect your Personal Data, but no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security.</p>
            </div>
            <div>
                <p className='subtitle'>Children's Privacy</p>
                <p>Our Service does not address anyone under 13. We do not knowingly collect personally identifiable information from anyone under 13. If we become aware of such collection, we will take steps to remove that information.</p>
            </div>
            <div>
                <p className='subtitle'>Links to Other Websites</p>
                <p>Our Service may contain links to other websites not operated by us. We advise you to review the Privacy Policy of every site you visit. We are not responsible for the content, privacy policies, or practices of any third-party sites or services.</p>
            </div>
            <div>
                <p className='subtitle'>Changes to this Privacy Policy</p>
                <p>We may update our Privacy Policy from time to time. Changes will be posted on this page with an updated "Last updated" date. We will notify you via email and/or a prominent notice on our Service prior to changes becoming effective.</p>
            </div>
            <div>
                <p className='subtitle'>Contact Us</p>
                <p>If you have any questions about this Privacy Policy, you can contact us by visiting this page on our website: <span className='email'>https://pasigborongan.com/</span></p>
            </div>
        </div>
    );
}

export default PrivacyAgreement;

